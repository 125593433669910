<template>
  <div>
       <!-- Start of breadcurmb section
        ============================================= -->
        <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
            <div class="container">
                <div class="breadcurmb-title text-center">
                    <h1>QUI SOMME NOUS</h1>
                </div>
                <div class="breadcurmb-item-list text-center ul-li">
                 <ul class="saasio-page-breadcurmb">
                     <li><router-link to="/">ACCUEIL</router-link></li>
                     <li><a href="#"> QUI SOMME NOUS</a></li>
                 </ul>
             </div>
         </div>
     </section>
    <!-- End of breadcurmb section ============================================= --> 

    <!-- Start of about section ============================================= -->
    <AboutContent />
    <!-- End of About  section  ============================================= -->

    <!-- Start of About Us section ============================================= -->
    <section id="str-about" class="str-about-section position-relative">
        <div class="str-aboutbg1 position-absolute"><img src="assets/img/startup/shape/vs1.png" alt=""></div>
        <div class="str-aboutbg2 position-absolute"><img src="assets/img/startup/shape/vs2.png" alt=""></div>
        <div class="str-aboutbg3 position-absolute"><img src="assets/img/startup/shape/vs3.png" alt=""></div>
        <div class="container">
            <div class="str-about-content">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="str-about-text wow fadeFromRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div class="str-section-title text-left str-title-left str-headline">
                                <span class="str-title-tag">About us</span>
                                <h2>Work with us to get
                                    the best services.
                                </h2>
                            </div>
                            <div class="str-about-textarea">
                                As a app web crawler expert, I help organizations adjust to the expanding significance of internet promoting.
                            </div>
                            <div class="str-about-details">
                                As a app web crawler expert, I help organizations adjust to the expanding significance of internet promoting.
                                Lorem Ipsum is simply dummy text of the printing and typ
                                esetting industry is simply dummy text
                            </div>
                            <div class="str-about-list ul-li-block">
                                <ul>
                                    <li>Qhen an unknown printer took a galley of type.</li>
                                    <li>It was popularised in the 1960s with the release.</li>
                                    <li>Aldus PageMaker including versions.</li>
                                </ul>
                            </div>
                            <a class="str-btn d-inline-block" href="#">Go to our team <i class="fas fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="str-about-img position-relative wow fadeFromLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                            <div class="str-about-shape"><img src="assets/img/startup/shape/abs.png" alt=""></div>
                            <div class="str-about-logo"><img src="assets/img/startup/about/ab-logo.png" alt=""></div>
                            <div class="str-about-img-field">
                                <div class="str-about-pic">
                                    <img src="assets/img/startup/about/ab1.png" alt="">
                                </div>
                                <div class="str-progress-area str-headline">
                                    <h3>Mentorship</h3>
                                    <div id="progress1" class="barfiller">
                                        <div class="tipWrap"><span class="tip"></span></div>
                                        <span class="fill" data-percentage="75"></span>
                                    </div>
                                    <h3>UI/UX </h3>
                                    <div id="progress2" class="barfiller">
                                        <div class="tipWrap"><span class="tip"></span></div>
                                        <span class="fill" data-percentage="70"></span>
                                    </div>
                                    <h3>Marketing</h3>
                                    <div id="progress3" class="barfiller">
                                        <div class="tipWrap"><span class="tip"></span></div>
                                        <span class="fill" data-percentage="90"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="str-about-img-field">
                                <div class="str-about-pic">
                                    <img src="assets/img/startup/about/ab2.png" alt="">
                                </div>
                                <div class="str-about-pic">
                                    <img src="assets/img/startup/about/ab3.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="str-about-feature-area">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="str-about-feature-box clearfix wow fadeFromLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div class="str-about-feature-icon text-center float-left">
                                    <i class="flaticon-null-8"></i>
                                </div>
                                <div class="str-about-feature-text str-headline">
                                    <h3>Best customer
                                        support 
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="str-about-feature-box clearfix wow fadeFromLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div class="str-about-feature-icon text-center float-left">
                                    <i class="flaticon-null-7"></i>
                                </div>
                                <div class="str-about-feature-text str-headline">
                                    <h3>Money back
                                        guarantee 
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="str-about-feature-box clearfix wow fadeFromLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div class="str-about-feature-icon text-center float-left">
                                    <i class="flaticon-null-6"></i>
                                </div>
                                <div class="str-about-feature-text str-headline">
                                    <h3>Lowest cost
                                        provider
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of About Ud section ============================================= --> 
           

  </div>
</template>

<script>
import AboutContent from '../components/AboutContent.vue';
export default {
    name : 'About',
    components: {
    AboutContent
},
    methods: {
        goToContact: function(){
            setTimeout(() =>{
                this.$router.push('/contact')
            }, 300)
            
        }
    }
}
</script>

<style>

</style>